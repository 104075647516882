/**
 * /* eslint-disable jsx-a11y/anchor-is-valid
 *
 * @format
 */

import React, { Suspense } from "react";
import "./App.less";
import "/node_modules/react-grid-layout/css/styles.css";
import {
	BrowserRouter as Router,
	Route,
	Switch,
	Redirect,
} from "react-router-dom";
import Loading from "./index/loading";
import WaterMarkContent from "./components/WaterMarkContent";
import cookie from "react-cookies"; // 导入对应库
const Home = React.lazy(() => import("./index/home"));
const Login = React.lazy(() => import("./index/login"));
const Proteomics = React.lazy(() =>
	import("./index/components/orderform/Proteomics"),
);
const ProteomicsHistory = React.lazy(() =>
	import("./index/components/orderform/ProteomicsHistory"),
);

const Edit = React.lazy(() => import("./index/components/userInfo/Edit"));
const Portal = React.lazy(() => import("./client/portal"));
const SampleConfirmation = React.lazy(() =>
	import("./mobile/salesman/sampleConfirmation"),
);

const Finish = React.lazy(() => import("./mobile/salesman/finish"));
const ProjectDetails = React.lazy(() =>
	import("./index/components/projectManagement/projectDetails"),
);
const ProjectLeaderDetails = React.lazy(() =>
	import("./index/components/projectManagement/projectLeaderDetails"),
);
const ClientIndex = React.lazy(() => import("./client/clientIndex"));
const Project_non_standard = React.lazy(() =>
	import("./index/components/projectManagement/project-non-standard"),
);
const Create_non_Standard = React.lazy(() =>
	import("./index/components/projectManagement/create_non_standard"),
);

const StaticalPreview = React.lazy(() =>
	import("./index/components/projectManagement/statisticalPreview"),
);

const Document = React.lazy(() => import("./document/Document"));
const DocumentOSS = React.lazy(() => import("./document/components/oss"));
const LIMSDocument = React.lazy(() =>
	import("./document/components/LIMSDocument"),
);
const LargeScreen = React.lazy(() =>
	import("./index/components/DataScreen/largeScreen"),
);
const ReportIndex = React.lazy(() => import("./client/report/ReportIndex"));

const Epiboly = React.lazy(() =>
	import("./index/components/projectManagement/components/epiboly"),
);

const AutomatedReport = React.lazy(() =>
	import("./AutomatedReporting/AutomatedReport"),
);

const gateway = React.lazy(() => import("./gateway/Index"));

/**
 * 移动端
 */
const startPage = React.lazy(() => import("./mobile/startPage"));
const mobileHome = React.lazy(() => import("./mobile/home/home"));
const HomeComponents = React.lazy(() =>
	import("./mobile/home/components/home/homeComponents"),
);
const knowledge = React.lazy(() =>
	import("./index/components/knowledgeBase/knowledgeBase"),
);

const TaskView = React.lazy(() =>
	import("./index/components/projectSpecification/TaskView"),
);
const SampleDetails = React.lazy(() => import("./mobile/SampleDetails"));
const SampleExpirationNotificationLetter = React.lazy(() =>
	import("./client/SampleExpirationNotificationLetter"),
);
const PerformanceEvaluationForm = React.lazy(() =>
	import("./client/PerformanceEvaluationForm"),
);

const BIReport = React.lazy(() => import("./index/components/reportsSystem/components/BIReport"));

const WorkList = React.lazy(() => import("./workList/WorkList"));
export default class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isPhone: false,
			loading: true,
			timer: null,
			clientHeight: document.documentElement.clientHeight, //屏幕高度
			router: [],
			userInfo: {},
			token: "",
		};
		this.resize = this.resize.bind(this);
	}

	componentDidMount() {
		this.loadingShow();
		window.addEventListener("resize", this.resize); //增加

		var info = navigator.userAgent;
		//通过正则表达式的test方法判断是否包含“Mobile”字符串
		var isPhone = /mobile/i.test(info);
		//如果包含“Mobile”（是手机设备）则返回true

		this.setState({
			isPhone,
		});
		// document.documentElement.addEventListener(
		// 	"touchstart",
		// 	function (event) {
		// 		if (event.touches.length > 1) {
		// 			event.preventDefault();
		// 		}
		// 	},
		// 	{
		// 		passive: false,
		// 	},
		// );

		// new DevicePixelRatio().init();
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.resize); //取消
	}

	detectZoom = () => {
		let ratio = 0,
			screen = window.screen,
			ua = navigator.userAgent.toLowerCase();
		if (window.devicePixelRatio !== undefined) {
			ratio = window.devicePixelRatio;
		} else if (~ua.indexOf("msie")) {
			if (screen.deviceXDPI && screen.logicalXDPI) {
				ratio = screen.deviceXDPI / screen.logicalXDPI;
			}
		} else if (
			window.outerWidth !== undefined &&
			window.innerWidth !== undefined
		) {
			ratio = window.outerWidth / window.innerWidth;
		}
		if (ratio) {
			ratio = Math.round(ratio * 100);
		}
		return ratio;
	};

	resize() {
		this.setState({ clientHeight: document.documentElement.clientHeight }); //监听
	}

	loadingShow = () => {
		let timer = this.state.timer;
		this.setState(
			{
				loading: true,
			},
			() => {
				clearTimeout(timer);
				this.setState({
					timer: setTimeout(() => {
						this.setState({
							loading: false,
						});
					}, 1000),
				});
			},
		);
	};

	render() {
		const { clientHeight, isPhone } = this.state;
		return (
			<>
				<WaterMarkContent
					user={
						localStorage.getItem("name") || cookie.load("name") || "iproteome"
					}>
					<div className='App' style={{ height: `${clientHeight}px` }}>
						{/*<Suspense fallback={<Loading/>}>*/}
						{/*    <Route path='/sampleDetails/:no?' component={SampleDetails} exact></Route>*/}
						{/*</Suspense>*/}

						{/* {isPhone ? (
                            <Suspense fallback={<Loading/>}>
                                <Router>
                                    <Switch>
                                        <Route path='/' component={startPage} exact></Route>
                                        <Redirect exact from='/home' to='/home/homeIndex'/>
                                        <Route path='/sampleDetails/:no?' component={SampleDetails} exact></Route>
                                        <Route path='/home' component={mobileHome}></Route>

                                        <Route
                                            path='/mobile/salesman/sampleConfirmation'
                                            component={SampleConfirmation}></Route>
                                        <Route
                                            path='/mobile/salesman/finish'
                                            component={Finish}
                                            exact></Route>
                                        <Route
                                            path='/mobile/home/:id'
                                            component={HomeComponents}
                                            exact></Route>
                                        <Route path='/home/knowledge' component={knowledge}/>
                                    </Switch>
                                </Router>
                            </Suspense>
                        ) : ( */}
						<div className='Body'>
							<Suspense fallback={<Loading />}>
								<Router>
									<Switch>
										{isPhone ? (
											<Route path='/' component={startPage} exact></Route>
										) : (
											<Route path='/' component={Login} exact></Route>
										)}
										<Route
											path='/taskview/:id?'
											component={TaskView}
											exact></Route>
										<Redirect
											exact
											from='/backstage'
											to='/backstage/homepage'
										/>
										<Route path='/backstage' component={Home}></Route>
										<Route
											path='/sampleDetails/:no?'
											component={SampleDetails}
											exact></Route>
										<Route
											path='/Proteomics'
											component={Proteomics}
											exact></Route>
										<Route path='/edit' component={Edit} exact></Route>
										<Route
											path='/ProteomicsHistory'
											component={ProteomicsHistory}
											exact></Route>
										<Route path='/portal' component={Portal} exact></Route>
										/**mobile */
										<Route
											path='/mobile/salesman/sampleConfirmation'
											component={SampleConfirmation}></Route>
										<Route
											path='/mobile/salesman/finish'
											component={Finish}
											exact></Route>
										/**project */
										<Route
											path='/project/staticalPreview'
											component={StaticalPreview}
											exact></Route>
										<Route
											path='/project/projectDetails'
											component={ProjectDetails}
											exact></Route>
										<Route
											path='/project/projectLeaderDetails'
											component={ProjectLeaderDetails}
											exact></Route>
										<Route
											path='/project/epiboly'
											component={Epiboly}
											exact></Route>
										/**client */
										<Redirect exact from='/web' to='/web/index' />
										<Route path='/web' component={gateway}></Route>
										<Route path='/portal' component={Portal} exact></Route>
										<Route
											path='/client/home'
											component={ClientIndex}
											exact></Route>
										<Route
											path='/client/report/:id'
											component={ReportIndex}
											exact></Route>
										/**Project_non_standard */
										<Route
											path='/project-non-standard'
											component={Project_non_standard}
											exact></Route>
										<Route
											path='/project-non-standard/createproject'
											component={Create_non_Standard}
											exact></Route>
										<Route
											path='/largeScreen'
											component={LargeScreen}
											exact></Route>
										/** document */
										<Route path='/document' component={Document} exact></Route>
										<Route path='/document/oss' component={DocumentOSS}></Route>
										<Route
											path='/document/LIMSdocx'
											component={LIMSDocument}></Route>
										{/* 科服报告 */}
										<Route
											path='/report/:pm'
											component={AutomatedReport}></Route>
										<Route
											path='/sampleExpirationNotificationLetter/:secret_key'
											component={SampleExpirationNotificationLetter}></Route>
										<Route
											path='/PerformanceEvaluationForm'
											component={PerformanceEvaluationForm}></Route>
										<Route
											path='/BIReport'
											component={BIReport}></Route>
										<Route
											path='/WorkList'
											component={WorkList}></Route>
									</Switch>
								</Router>
							</Suspense>
						</div>
					</div>
				</WaterMarkContent>
			</>
		);
	}
}
